<template>
  <v-container>
    <v-list two-line>
      <template v-for="{ id, name, email, body} in comments">
        <v-list-item :key="id" class="px-1">
          <template v-slot:default>
            <v-list-item-content class="comments">
              <v-list-item-title class="name">{{ `${ id }: ${ name }` }}</v-list-item-title>
              <v-list-item-subtitle class="email">{{ email }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ body }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-list-item>
        <v-divider :key="`divider-${id}`"></v-divider>
      </template>
    </v-list>
    <div class="mt-3 pt-3 text-center">
      <v-btn class="teal darken-4 mx-5" @click="prev" :disabled="disable.prev">Prev</v-btn>
      <div class="pages">
        <span v-for="n in pages" :key="n" :class="{active: (currentPage + 1) === n}"
          @click.stop="toPage(n)">{{n}}</span>
      </div>
      <v-btn class="teal darken-4 mx-5" @click="next" :disabled="disable.next">Next</v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Comments',

  computed: {
    ...mapGetters(['pages', 'comments', 'currentPage', 'itemsPerPage', 'disable'])
  },
  methods: {
    prev () {
      this.$store.dispatch('updateCurrentPage', this.currentPage - 1)
    },
    next () {
      this.$store.dispatch('updateCurrentPage', this.currentPage + 1)
    },
    toPage (page) {
      this.$store.dispatch('updateCurrentPage', page - 1)
    }
  }
}
</script>
