<template>
  <div id="Navigation">
    <v-app-bar app color="teal darken-4" style="z-index: 10;" flat clipped-right dark>
      <v-container class="py-0 fill-height" :class="{'px-0': $vuetify.breakpoint.xs}">
        <v-avatar class="mr-2" :size="$vuetify.breakpoint.xs ? 40 : 48">
          <img :src = logo alt="logo" class="logo">
        </v-avatar>
        <v-toolbar-title
          :class="{'display-1': !$vuetify.breakpoint.xs}">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch inset hide-details color="#41b883" v-model="fetchOnce"
          :label="`Mode: Fetch ${fetchOnce ? 'All' : 'Pages'}`" />
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data: () => {
    return {
      logo: require('@/assets/logo.png'),
      title: 'FACTS'
    }
  },

  mounted () {
    this.$store.dispatch('fetchComments')
  },

  computed: {
    fetchOnce: {
      get: vm => vm.$store.getters.fetchOnce,
      set (mode) { this.$store.dispatch('updateFetchingMode', mode) }
    }
  }
}
</script>
