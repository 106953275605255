<template>
  <Comments />
</template>

<script>
import Comments from '@/components/Comments'

export default {
  name: 'Home',
  components: {
    Comments
  }
}
</script>
