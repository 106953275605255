<template>
  <v-app id="facts">
    <Navigation />
    <v-main>
      <router-view/>
    </v-main>
    <Footer />

    <Snackbar />
  </v-app>
</template>

<script>
import Navigation from '@/components/Navigation'
import Snackbar from '@/components/Snackbar'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Navigation, Snackbar, Footer
  }
}
</script>
