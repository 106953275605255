<template>
  <div class="text-center ma-2" style="z-index: 5">
    <v-snackbar v-model="snackbar" :timeout=10000 >{{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="lime" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    snackbar: false
  }),
  watch: {
    '$store.getters.fetchOnce': {
      handler () {
        this.snackbar = true
      }
    }
  },

  computed: {
    text: {
      get: vm => {
        return vm.$store.getters.fetchOnce
          ? `Fetch All mode means that the API endpoint is only hit once during reload.
              Scrolling pages on pagination does not send any more HTTP requests`

          : `Fetch Pages mode means that the API endpoint is hit everytime during pagination.
              Request parameters are provided for the API to respond with limited comments.`
      }
    }
  }
}
</script>
