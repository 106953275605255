<template>
  <v-footer id="Footer" fixed dense app>
    <v-spacer></v-spacer>
    <span class="caption">&copy; {{ new Date().getFullYear() }}</span>
    <v-spacer></v-spacer>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
