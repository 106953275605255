import axios from 'axios'

const API_PATH = process.env.VUE_APP_API_PATH

const state = {
  totalCommentsCount: 0,
  itemsPerPage: 50,
  fetchOnce: false,
  currentPage: 0,
  comments: []
}

const getters = {
  pages: state => Math.ceil(state.totalCommentsCount / state.itemsPerPage),

  comments: state => {
    if (!state.fetchOnce) return state.comments

    const start = state.currentPage * state.itemsPerPage
    const end = start + state.itemsPerPage
    return state.comments.slice(start, end)
  },

  itemsPerPage: state => state.itemsPerPage,

  currentPage: state => state.currentPage,

  fetchOnce: state => state.fetchOnce,

  disable: state => {
    return {
      next: (state.currentPage + 1) * state.itemsPerPage >= state.totalCommentsCount,
      prev: state.currentPage <= 0
    }
  }
}

const actions = {
  updateFetchingMode: ({ commit, dispatch }, mode) => {
    commit('SET_FETCHING_MODE', mode)
    commit('RESET_COMMENTS', true)
    dispatch('fetchComments')
  },

  async fetchAllAvailableComments ({ commit }) {
    await axios.get(API_PATH)
      .then(response => {
        commit('RESET_COMMENTS', true)
        commit('UPDATE_TOTAL_COMMENTS_COUNT', response.data.length)
        response.data.map(comment => commit('CREATE_COMMENT', comment))
      })
      .catch(error => console.log(error))
  },

  async fetchCommentsPerPage ({ commit, getters }) {
    const _start = getters.currentPage * getters.itemsPerPage

    await axios.get(`${API_PATH}?_start=${_start}&_limit=${getters.itemsPerPage}`)
      .then(response => {
        commit('RESET_COMMENTS', false)
        commit('UPDATE_TOTAL_COMMENTS_COUNT', response.headers['x-total-count'])
        response.data.map(comment => commit('CREATE_COMMENT', comment))
      })
      .catch(error => console.log(error))
  },

  async fetchComments (context) {
    context.getters.fetchOnce
      ? context.dispatch('fetchAllAvailableComments')
      : context.dispatch('fetchCommentsPerPage')
  },

  updateCurrentPage: ({ commit, dispatch, getters }, page) => {
    commit('SET_CURRENT_PAGE', page)
    if (!getters.fetchOnce) dispatch('fetchCommentsPerPage')
  }
}

const mutations = {
  UPDATE_TOTAL_COMMENTS_COUNT: (state, val) => (state.totalCommentsCount = val),

  CREATE_COMMENT: (state, comment) => state.comments.push(comment),

  SET_CURRENT_PAGE: (state, page) => (state.currentPage = page),

  SET_FETCHING_MODE: (state, mode) => (state.fetchOnce = mode),

  RESET_COMMENTS: (state, resetCounter) => {
    state.currentPage = resetCounter ? 0 : state.currentPage
    state.comments = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
